.grid-simple table.md-table tbody.md-body>tr.md-row{
    height: 26px !important;
}

.grid-simple table.md-table tbody.md-body>tr>td{
    height: 32px !important;
}

.grid-simple table.md-table thead.md-head>tr.md-row{
    height: 28px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.grid-simple table.md-table td.md-cell{
    border-top: 0px;
}

.grid-simple .row-top-border{
    border-top: 3px solid rgba(0, 0, 0, 0.2) !important;
}

.grid-simple h5{
    color: #4a4a4a;
}

.grid-simple .toolbar-button.md-button{
    margin: 0px !important;
    min-width: 0px !important;
}

.grid-simple table.md-table td.md-cell:last-child{
    padding: 0px !important;
}

.grid-simple table.md-table td.md-cell:first-child{
    padding: 0px !important;
}

.grid-simple table.md-table th.md-cell:first-child{
    padding: 0px !important;
}

.grid-simple .action-buttons-menu{
    padding: 0px;
}

.grid-simple .action-buttons-menu .md-button{
    margin: 0px;
    min-height: 0px;
    height: 32px;
    padding: 0px;
    border-radius: 0%;
}

.grid-simple table.md-table.md-row-select td.md-cell:first-child, 
.grid-simple table.md-table.md-row-select th.md-column:first-child,
.grid-simple table.md-table:not(.md-row-select) th.md-column:first-child{
    padding: 0px;
}

.grid-simple table.md-table.md-row-select tbody.md-body>tr.md-row.md-selected{
    background-color: transparent !important;
}

.grid-simple table.md-table.md-row-select th.md-column{
    padding: 0 32px 0 0 !important;
}

.grid-simple table.md-table.md-row-select th.md-column:last-child{
    padding: 0 16px 0 0 !important;
}

.grid-simple table.md-table.md-row-select td.md-cell:nth-child(2){
    padding: 0px;
}

.tighter-grid table.md-table:not(.md-row-select) td.md-cell
,.tighter-grid table.md-table:not(.md-row-select) th.md-column{
    padding: 0 30px 0 0 !important;
}