.selectdemoSelectHeader {
    /* Please note: All these selectors are only applied to children of elements with the 'selectdemoSelectHeader' class */ }
.selectdemoSelectHeader .demo-header-searchbox {
    border: none;
    outline: none;
    /* height: 100%; */
    padding: 0; 
}

.demo-select-header .md-errors-spacer {
    display: none;
}

.selectdemoSelectHeader md-checkbox {
    margin-bottom: 0;
    width: 150px;
}

.selectdemoSelectHeader .demo-select-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 10.667px;
    padding-right: 10.667px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: auto; }

.selectdemoSelectHeader md-content._md {
    max-height: 240px; }

    .selectdemoSelectHeader .md-select-value .md-text {
        display: inline-block !important;
        visibility: visible;
      }
    
md-select-menu[multiple] md-option .md-text {
    max-width: 700px !important;
    white-space: inherit !important;
}